import React from "react"
import Image from "./imageComponent"
import { Link } from "gatsby"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

class ProjectCard extends React.Component {
  render() {
    const { project, styles } = this.props
    const altText = project.title + " " + project.services.toString()

    return (
      <div className={styles.projectCard}>
        <ScrollAnimation
          animateIn="animate__fadeIn"
          animateOnce={true}
          duration={0.5}
        >
          <Link to={this.props.link + "/"}>
            <Image
              alt={altText}
              imgClass={styles.projectImg}
              fileName={project.imgFile + ".jpg"}
              animated={false}
            />
          </Link>
        </ScrollAnimation>
        <div className={styles.projectTitle}>{project.title}</div>
        {
          //<p className={styles.projectDescription}>{project.description}</p>
        }
      </div>
    )
  }
}

export default ProjectCard
